import Spinner from 'components/Spinner';
import SvgEllipsisV from 'components/icons/EllipsisV';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components-ui/DropdownMenu';
import { Button } from '../../../components-ui/Button';
import React from 'react';

interface Props {
  loading?: boolean;
  specialEventId: number;
  handleEdit?: (id: number) => void;
  handleDelete?: (id: number) => void;
}

export function SpecialEventTDM({ loading, specialEventId, handleEdit, handleDelete }: Props) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={loading} asChild>
        <Button className="rounded-full" variant="ghost" size="sm">
          {loading ? (
            <div style={{ width: '36px' }}>
              <Spinner size="20px" />
            </div>
          ) : (
            <SvgEllipsisV
              style={{
                fontSize: 18,
              }}
            />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-52 font-normal" align="center" side="left">
        <DropdownMenuItem onSelect={() => handleEdit(specialEventId)}>Edit event</DropdownMenuItem>
        <DropdownMenuItem onSelect={() => handleDelete(specialEventId)} className="text-red-400">
          Delete event
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
