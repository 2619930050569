import React, { useEffect, useState } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../../components-ui/AlertDialog';
import dayjs from 'dayjs';
import Divider from '../../../../components/Divider';
import Button from '../../../../components/Button';
import styled from 'styled-components';
import Checkbox from '../../../../components/Checkbox';

export const DuplicateEventsDialog = ({ duplicates, isOpen, onClose, onConfirm, numberOfImportedEvents }) => {
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);

  // Reset state when dialog closes or when unmounted
  useEffect(() => {
    if (!isOpen) {
      setSelectedEvents([]);
    }
  }, [isOpen]);

  const handleSelectEvent = (specialEventId: number) => {
    setSelectedEvents((prev) =>
      prev.includes(specialEventId) ? prev.filter((id) => id !== specialEventId) : [...prev, specialEventId],
    );
  };

  const handleKeepExisting = () => {
    const selections = Object.fromEntries(duplicates.map((id) => [id, 'existing']));
    onConfirm(selections);
    setSelectedEvents([]); // Reset state after confirmation
  };

  const handleReplaceWithNew = () => {
    const selections = Object.fromEntries(selectedEvents.map((id) => [id, 'new']));
    onConfirm(selections);
    setSelectedEvents([]); // Reset state after confirmation
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {duplicates?.length} of {numberOfImportedEvents} events cannot be imported
          </AlertDialogTitle>
          <div className="text-sm font-normal text-neutral-400">
            The remaining {duplicates?.length} events with the following names already exist in your events menu.
          </div>
        </AlertDialogHeader>

        <div className="flex flex-col items-start justify-start">
          {duplicates.map((duplicate, index) => (
            <React.Fragment key={duplicate.existing.specialEventId}>
              <div className="w-full">
                <StyledCheckbox
                  checked={selectedEvents.includes(duplicate.existing.specialEventId)}
                  onChange={() => handleSelectEvent(duplicate.existing.specialEventId)}
                  value={duplicate.existing.specialEventId.toString()}
                  name="duplicateEvent"
                  className="w-full"
                  label={
                    <div className="flex flex-row items-center justify-between text-sm">
                      <span className="font-medium text-neutral-500">{duplicate.existing.name}</span>
                      <span className="text-xs text-neutral-400">
                        {dayjs(duplicate.existing.start).format('MMM D, YYYY')} -{' '}
                        {dayjs(duplicate.existing.end).format('MMM D, YYYY')}
                      </span>
                    </div>
                  }
                />
                {index < duplicates.length - 1 && <Divider className="!m-1" />}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="text-sm font-normal text-neutral-400">What do you want to do?</div>
        <AlertDialogFooter>
          <div className="flex gap-2">
            <Button variant="contained" color="secondary" size="small" onClick={handleKeepExisting}>
              Keep existing events
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={selectedEvents.length === 0}
              onClick={handleReplaceWithNew}
            >
              Replace selected
            </Button>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const StyledCheckbox = styled(Checkbox)`
  .label {
    justify-content: flex-start;
    label {
      width: 100%;
    }
  }
`;
