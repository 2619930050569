import { NexoyaContentRule, NexoyaContentV2 } from '../../types';
import { HoverableTooltip, HoverCard, HoverCardContent, HoverCardTrigger } from '../../components-ui/HoverCard';
import React from 'react';
import AvatarProvider from '../AvatarProvider';

interface Props {
  contents?: NexoyaContentV2[];
  contentRules?: NexoyaContentRule[];
  tooltip: React.JSX.Element;
}
const ImpactedContentsHoverCard = ({ contents, contentRules, tooltip }: Props) => (
  <HoverCard>
    <HoverCardTrigger>
      <HoverableTooltip className="w-fit">{tooltip}</HoverableTooltip>
    </HoverCardTrigger>
    <HoverCardContent side="bottom" align="end" className="flex w-full min-w-96 flex-col items-start justify-start">
      <div className="text-mdlg text-neutral-800">{contents.length} Impacted contents</div>
      {contentRules?.length ? (
        <>
          <div className="flex">
            <div className="mb-2 text-xs text-neutral-400">{contentRules?.length} content rules</div>
            <div className="flex flex-wrap gap-2">
              {contentRules?.map((contentRule) => (
                <div
                  key={contentRule?.contentRuleId}
                  className="rounded-md border border-neutral-100 px-3 py-1.5 font-normal text-neutral-500"
                >
                  <span className="font-medium">{contentRule.name}:</span>{' '}
                  <span>{contentRule.appliedDiscoveredContents.length} contents</span>
                </div>
              ))}
            </div>
          </div>
          <div className="h-[1px] w-full text-neutral-50" />
        </>
      ) : null}
      {contents.length ? (
        <div>
          <div className="mb-2 text-xs text-neutral-400">{contents?.length} individually added contents</div>
          <div className="flex flex-col flex-wrap gap-2">
            {contents.map((content) => (
              <div
                key={content?.contentId}
                className="flex w-fit items-center justify-start gap-2 rounded-md border border-neutral-100 px-3 py-1"
              >
                <AvatarProvider providerId={content?.provider?.provider_id} size={32} />
                <span className="text-md font-normal text-neutral-500">{content?.title}</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </HoverCardContent>
  </HoverCard>
);

export default ImpactedContentsHoverCard;
