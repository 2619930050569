import { NexoyaEventCategory } from '../types';

export const humanReadableEventCategory = (category: NexoyaEventCategory) => {
  switch (category) {
    case NexoyaEventCategory.ProductLaunch:
      return '🚀 Product Launch';
    case NexoyaEventCategory.PromotionAndDiscounts:
      return '💸 Low Funnel Push';
    case NexoyaEventCategory.BrandAwareness:
      return '📢 High Funnel Push';
    case NexoyaEventCategory.PositiveExternalEffects:
      return '🔥 Higher Activity';
    case NexoyaEventCategory.NegativeExternalEffects:
      return '💤 Lower Activity';
    default:
      return category;
  }
};

export const getCategoryInfo = (category: NexoyaEventCategory) => {
  switch (category) {
    case NexoyaEventCategory.BrandAwareness:
      return {
        title: '📢 Brand Awareness',
        description: 'This includes awareness flights, offline marketing, TV and more.',
      };
    case NexoyaEventCategory.NegativeExternalEffects:
      return {
        title: '💤 Negative external effects',
        description:
          'Periods of lower market activity. Often tied to holidays, specific external, macroeconomic changes and competitor activity.',
      };
    case NexoyaEventCategory.ProductLaunch:
      return {
        title: '🚀 Product Launch',
        description: 'This includes the launch of a new product or product category.',
      };
    case NexoyaEventCategory.PositiveExternalEffects:
      return {
        title: '🔥 Positive external effects',
        description:
          'Periods of higher market activity. Often tied to holidays, specific external, macroeconomic changes and competitor activity.',
      };
    case NexoyaEventCategory.PromotionAndDiscounts:
      return {
        title: '💸 Promotion and discounts',
        description: 'This includes price decreases, special offers, loyalty push and low funnel push efforts.',
      };
    case NexoyaEventCategory.Markup:
      return {
        title: '💲 Markup',
        description: 'This includes markups, price changes, and more.',
      };
    case NexoyaEventCategory.TrackingIssue:
      return {
        title: '⚡️ Tracking issues',
        description:
          'The event timeframe either has no performance data or inaccurate data. Nexoya will not use this category for its Machine Learning training purposes.',
      };
  }
};
