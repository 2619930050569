import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components-ui/AlertDialog';
import ButtonAsync from 'components/ButtonAsync';
import { LabelLight } from 'components/InputLabel/styles';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components-ui/Select';
import {
  NexoyaApplicableContentRule,
  NexoyaApplicableImpactGroupRule,
  NexoyaContentRule,
  NexoyaDiscoveredContent,
  NexoyaImpactGroupRule,
  NexoyaUpdateContentRuleUserChoiceInput,
  NexoyaUpdateImpactGroupRuleUserChoiceInput,
} from '../../../../../types';
import SvgWarningTwo from '../../../../../components/icons/WarningTwo';
import SvgInfoCircle from '../../../../../components/icons/InfoCircle';
import { Separator } from '../../../../../components-ui/Separator';
import { cn } from '../../../../../lib/utils';
import PortfolioRuleHoverCard from '../../../../../components/HoverCard';
import ContentHoverCard from '../../../../../components/HoverCard/ContentHoverCard';

enum MatchStatus {
  NEW = 'NEW',
  NO_MATCH = 'NO_MATCH',
}

const isContentRule = (rule): rule is NexoyaContentRule => 'contentRuleId' in rule;

const getColorBasedOnMatchStatus = (status: MatchStatus) => {
  switch (status) {
    case MatchStatus.NEW:
      return 'bg-[#88E7B7]';
    case MatchStatus.NO_MATCH:
      return 'bg-red-200';
    default:
      return '';
  }
};

interface NexoyaDiscoveredContentWithStatus extends NexoyaDiscoveredContent {
  matchStatus?: { status: MatchStatus; text: string };
}

interface Props<T> {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
  // Functions to extract the list of rules, a rule's ID, and a rule's display name
  getRules: (dsc: NexoyaDiscoveredContent) => Array<T | null> | undefined;
  getRuleName: (rule: T) => string;
  type: 'content rule' | 'impact group rule';
  newMatchingDiscoveredContents: NexoyaDiscoveredContent[];
  noLongerMatchingDiscoveredContents: NexoyaDiscoveredContent[];
  userChoices: NexoyaUpdateContentRuleUserChoiceInput[] | NexoyaUpdateImpactGroupRuleUserChoiceInput[];
  setUserChoices: Dispatch<
    SetStateAction<NexoyaUpdateContentRuleUserChoiceInput[] | NexoyaUpdateImpactGroupRuleUserChoiceInput[]>
  >;
  resetUserChoices: () => void;
  rule: NexoyaContentRule | NexoyaImpactGroupRule;
}

export const PreviewEditDialog = <T,>({
  newMatchingDiscoveredContents,
  noLongerMatchingDiscoveredContents,
  isOpen,
  onCancel,
  onConfirm,
  loading,
  getRules,
  getRuleName,
  type,
  setUserChoices,
  userChoices,
  rule,
}: Props<T>) => {
  const mergedDiscoveredContents = [
    ...newMatchingDiscoveredContents.map((dsc) => ({
      ...dsc,
      matchStatus: { status: MatchStatus.NEW, text: 'New match' },
    })),
    ...noLongerMatchingDiscoveredContents.map((dsc) => ({
      ...dsc,
      matchStatus: { status: MatchStatus.NO_MATCH, text: 'No longer a match' },
    })),
  ];

  const getSelectedUserChoice = (contentId: number) => {
    const propertyKey = isContentRule(rule) ? 'applyOtherContentRuleId' : 'applyOtherImpactGroupRuleId';
    // @ts-ignore
    return userChoices.find((choice) => choice.contentId === contentId)?.[propertyKey];
  };

  const handleUserChoiceChange = (contentId: number, newValue: string) => {
    setUserChoices((prevChoices) => {
      const index = prevChoices.findIndex((choice) => choice.contentId === contentId);
      let updatedChoice;
      if (isContentRule(rule)) {
        // For content rules:
        // - 'keep_current': keep current assignment (no removal, no new assignment)
        // - 'remove_current': remove current assignment (set removal flag)
        // - Otherwise, treat newValue as new rule id to apply
        updatedChoice =
          newValue === 'keep_current'
            ? { contentId, removeFunnelStepMappings: false }
            : newValue === 'remove_current'
              ? { contentId, removeFunnelStepMappings: true }
              : {
                  contentId,
                  removeFunnelStepMappings: false,
                  applyOtherContentRuleId: Number(newValue),
                };
      } else {
        // For impact group rules:
        updatedChoice =
          newValue === 'keep_current'
            ? { contentId, removeImpactGroupAssignment: false }
            : newValue === 'remove_current'
              ? { contentId, removeImpactGroupAssignment: true }
              : {
                  contentId,
                  removeImpactGroupAssignment: false,
                  applyOtherImpactGroupRuleId: Number(newValue),
                };
      }
      if (index > -1) {
        return prevChoices.map((choice, idx) => (idx === index ? updatedChoice : choice));
      }

      return [...prevChoices, updatedChoice];
    });
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent className="min-w-[1580px]">
        <AlertDialogHeader className="space-y-3">
          <AlertDialogTitle className="mb-4">Review and apply changes</AlertDialogTitle>
          <AlertDialogDescription>
            <div className="mb-3 flex flex-col rounded-md border border-neutral-100 bg-neutral-50 p-3">
              <div className="flex gap-2">
                <SvgWarningTwo warningCircleColor="#FCF1BA" warningColor="#F5CF0F" style={{ height: 20, width: 20 }} />
                <span className="text-md leading-5 text-neutral-800">
                  Select metric assignments for certain contents
                </span>
              </div>
              <span className="ml-7 font-normal leading-5 text-neutral-700">
                The updated filters match {newMatchingDiscoveredContents.length} new contents and no longer match{' '}
                {noLongerMatchingDiscoveredContents?.length} contents. Select a metric assignment for these contents
                required.
              </span>
            </div>
            <div className="mb-3 flex flex-col rounded-md border border-neutral-100 bg-neutral-50 p-3">
              <div>
                <SvgInfoCircle style={{ height: 20, width: 20 }} />
                <span className="ml-2 font-normal leading-5 text-neutral-700">
                  Contents already in your portfolio will remain unchanged. Any contents without a metric assignment
                  from a content rule can be managed in{' '}
                  <span className="font-semibold">Portfolio Settings {'>'} Unapplied Rules</span>.
                </span>
              </div>
            </div>
          </AlertDialogDescription>
          <div className="rounded-lg border border-neutral-100 bg-neutral-50">
            <div className="grid grid-cols-6 items-center px-6 py-3 font-medium text-neutral-600">
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Content name</LabelLight>
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Status</LabelLight>
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">In portfolio?</LabelLight>
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Previous match</LabelLight>
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Matched with {type}</LabelLight>
              <LabelLight className="!mb-0 justify-self-end px-0 font-semibold !text-neutral-500">{type}</LabelLight>
            </div>
            <div className="max-h-96 overflow-x-scroll">
              {mergedDiscoveredContents.map((dsc) => {
                const contentId = dsc.content?.contentId;
                // @ts-ignore
                const rules: NexoyaApplicableContentRule[] | NexoyaApplicableImpactGroupRule[] = getRules(dsc) || [];
                // @ts-ignore
                const previouslyAppliedRules = rules.filter((rule) => rule.isApplied);

                return (
                  <div
                    key={`${dsc.matchStatus?.status}-${contentId}-${dsc.content?.portfolioContentId || 'new'}`}
                    className="grid grid-cols-6 border-t border-neutral-100 px-6 py-4"
                  >
                    <ContentHoverCard
                      content={dsc?.content}
                      tooltipClassName="max-w-52 truncate overflow-ellipsis"
                      tooltip={<span className="text-neutral-900">{dsc.content?.title}</span>}
                    />

                    <div
                      className={cn(
                        'flex h-fit w-full max-w-44 items-center justify-center rounded-full px-2 py-0.5 text-neutral-900',
                        getColorBasedOnMatchStatus(dsc.matchStatus?.status),
                      )}
                    >
                      {dsc.matchStatus?.text}
                    </div>
                    <div className="flex items-center text-neutral-900">
                      {dsc.content?.portfolioContentId ? 'Yes' : 'No'}
                    </div>
                    <div
                      className={cn(
                        'flex items-center',
                        previouslyAppliedRules.length ? 'text-neutral-900' : 'text-neutral-300',
                      )}
                    >
                      {previouslyAppliedRules.length
                        ? previouslyAppliedRules.map((rule, idx) => (
                            <PortfolioRuleHoverCard
                              key={idx}
                              rule={
                                rule.__typename === 'ApplicableContentRule' ? rule.contentRule : rule.impactGroupRule
                              }
                              tooltip={<span className="max-w-44 truncate overflow-ellipsis">{getRuleName(rule)}</span>}
                            />
                          ))
                        : 'No metrics'}
                    </div>
                    <div
                      className={cn('flex items-center gap-3', rules?.length ? 'text-neutral-900' : 'text-neutral-300')}
                    >
                      {rules.length
                        ? rules.map((rule, idx) => (
                            <PortfolioRuleHoverCard
                              key={idx}
                              rule={
                                rule.__typename === 'ApplicableContentRule' ? rule.contentRule : rule.impactGroupRule
                              }
                              tooltip={<span className="max-w-44 truncate overflow-ellipsis">{getRuleName(rule)}</span>}
                            />
                          ))
                        : `No ${type}s`}
                    </div>
                    <div className="flex w-full justify-end">
                      <ContentRuleSelect
                        dsc={dsc}
                        rule={rule}
                        handleUserChoiceChange={handleUserChoiceChange}
                        getSelectedUserChoice={getSelectedUserChoice}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>
            <ButtonAsync
              disabled={loading}
              loading={loading}
              onClick={onCancel}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </ButtonAsync>
          </AlertDialogAction>
          <AlertDialogAction>
            <ButtonAsync
              disabled={loading || userChoices.length !== mergedDiscoveredContents?.length}
              loading={loading}
              onClick={onConfirm}
              variant="contained"
              color="primary"
              size="small"
            >
              Apply selection and confirm changes
            </ButtonAsync>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const ContentRuleSelect = ({
  dsc,
  rule,
  handleUserChoiceChange,
  getSelectedUserChoice,
}: {
  dsc: NexoyaDiscoveredContentWithStatus;
  rule: NexoyaContentRule | NexoyaImpactGroupRule;
  handleUserChoiceChange: (contentId: number, newValue: string) => void;
  getSelectedUserChoice: (contentId: number) => string;
}) => {
  const contentId = dsc.content?.contentId;
  const allRules = isContentRule(rule) ? dsc?.contentRules : dsc?.impactGroupRules;
  const [hasAutoSelected, setHasAutoSelected] = React.useState(false);

  // Filter out previously matched rules if status is NO_MATCH
  const rules =
    // @ts-ignore
    dsc.matchStatus?.status === MatchStatus.NO_MATCH ? allRules?.filter((rule) => !rule.isApplied) : allRules;

  const selectedUserChoice = getSelectedUserChoice(contentId)?.toString();

  // Pre-select the rule if there is only one rule possible
  useEffect(() => {
    if (rules?.length === 1 && !selectedUserChoice && !hasAutoSelected) {
      handleUserChoiceChange(
        contentId,
        rules[0].contentRule?.contentRuleId?.toString() || rules[0].impactGroupRule?.impactGroupRuleId?.toString(),
      );
      setHasAutoSelected(true);
    }
  }, [rules, contentId, handleUserChoiceChange, selectedUserChoice, hasAutoSelected]);

  return (
    <Select onValueChange={(value) => handleUserChoiceChange(contentId, value)} value={selectedUserChoice || undefined}>
      <SelectTrigger className="border-none bg-transparent p-2">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent className="rounded-lg text-white shadow-md">
        {dsc.matchStatus?.status !== MatchStatus.NEW && (
          <>
            <SelectItem value="keep_current">
              <div className="flex flex-col items-start justify-start">
                <span className="text-md whitespace-pre">Keep previous (detached) assignment</span>
                <span className="text-[10px] font-medium text-neutral-400">WILL GO INTO UNAPPLIED RULES</span>
              </div>
            </SelectItem>
            <SelectItem value="remove_current">
              <div className="flex flex-col items-start justify-start">
                <span className="text-md whitespace-pre">Remove previous assignment</span>
                <span className="text-[10px] font-medium text-neutral-400">WILL GO INTO UNAPPLIED RULES</span>
              </div>
            </SelectItem>
            {rules?.length ? <Separator className="my-2 bg-neutral-600" /> : null}
          </>
        )}
        {rules?.map((rule, idx) => (
          <SelectItem
            key={`${isContentRule(rule) ? 'content' : 'impact'}-${rule.contentRule?.contentRuleId || rule.impactGroupRule?.impactGroupRuleId}-${idx}`}
            value={rule.contentRule?.contentRuleId?.toString() || rule.impactGroupRule?.impactGroupRuleId?.toString()}
            className="text-left text-mdlg"
          >
            {rule.contentRule?.name || rule.impactGroupRule?.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
