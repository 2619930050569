import React, { Dispatch, SetStateAction, useState } from 'react';
import FormControlLabel from '../../../../components/FormControlLabel';
import Radio from '../../../../components/Radio';
import RadioGroup from '../../../../components/RadioGroup';
import Switch from '../../../../components/Switch';
import ContentSelectionTableV2 from '../../../../components/ContentSelection/components/ContentSelectionTableV2';
import { Input } from 'components-ui/Input';
import { useRouteMatch } from 'react-router';
import { DataTableFilterOption } from '../Content/ContentRule/types';
import {
  NexoyaContentFilterFieldName,
  NexoyaContentFilterOperator,
  NexoyaProvider,
  NexoyaSpecialEvent,
} from '../../../../types';
import { PortfolioRuleType } from '../Content/ContentRule/utils';
import { useProviders } from '../../../../context/ProvidersProvider';
import { PortfolioFilter } from '../PerformanceChartHeader/components/PortfolioFilter';
import { usePortfolio } from '../../../../context/PortfolioProvider';
import { useFilteredContentsStore } from '../../../../store/filter-contents';
import AvatarProvider from '../../../../components/AvatarProvider';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../../components-ui/Button';
import * as Styles from '../../../../components/PerformanceTable/styles';
import SvgChevronDown from '../../../../components/icons/ChevronDown';
import { LabelLight } from '../../../../components/InputLabel/styles';
import { getCategoryInfo } from '../../../../utils/specialEvents';
import dayjs from 'dayjs';
import Checkbox from '../../../../components/Checkbox';

const ASSIGN_CONTENTS_OPTIONS = [
  {
    value: 'specified_contents',
    label: 'Specified contents',
  },
  {
    value: 'all_contents',
    label: 'All contents',
  },
];

export const SpecialEventAssignContents = ({
  renderTitle = true,
  renderSubtitle = false,
  specialEvents,
  setIncludesAllContents,
  selectedEventIds,
  setSelectedEventIds,
}: {
  renderTitle?: boolean;
  renderSubtitle?: boolean;
  specialEvents?: NexoyaSpecialEvent[];
  setIncludesAllContents: Dispatch<SetStateAction<boolean>>;
  selectedEventIds?: number[];
  setSelectedEventIds?: Dispatch<SetStateAction<number[]>>;
}) => {
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [isSpecifiedContentsSelected, setIsSpecifiedContentsSelected] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isIndividualContentsExpanded, setIsIndividualContentsExpanded] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState<DataTableFilterOption[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const { activeProviders } = useProviders();
  const {
    providers: { providersFilter },
  } = usePortfolio();

  const { filteredContents, selectedContentIds, setSelectedContentIds } = useFilteredContentsStore();

  const providerIds = providersFilter?.length
    ? providersFilter.map((provider) => provider.provider_id)
    : activeProviders?.map((provider: NexoyaProvider) => provider.provider_id);

  const handleSelectEvent = (eventId: number) => {
    setSelectedEventIds((prev) => (prev.includes(eventId) ? prev.filter((id) => id !== eventId) : [...prev, eventId]));
  };

  const handleSearchInput = (value: string) => {
    setSearchInput(value);
    setSelectedOptions([
      {
        id: uuidv4(),
        label: 'Title',
        icon: null,
        value: NexoyaContentFilterFieldName.Title,
        options: [],
        type: 'string',
        filterValues: [value],
        filterOperator: NexoyaContentFilterOperator.Contains,
        operators: [],
      },
    ]);
  };

  return (
    <div className="flex flex-col gap-2 py-8">
      {renderTitle ? (
        <div>
          <div className="text-[20px] font-medium tracking-normal">Assign contents</div>
          <div className="text-md font-normal text-neutral-500">
            Select the content type and contents that are impacted by your event.
          </div>
        </div>
      ) : null}
      {specialEvents?.length ? (
        <div>
          <div>
            <div className="text-mdlg font-medium tracking-normal">Select events</div>
            <div className="text-md font-normal text-neutral-500">
              Select the events you want to assign contents to.
            </div>
          </div>
          <div className="mb-6 mt-4 divide-y divide-neutral-100 rounded-lg border border-neutral-100">
            <div className="grid grid-cols-[40px_1.45fr_1fr_1fr_1fr_0.3fr] items-center px-6 py-1 font-medium text-neutral-600">
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-300">
                <Checkbox
                  checked={selectedEventIds.length === specialEvents?.length}
                  onChange={() => {
                    setSelectedEventIds(
                      selectedEventIds.length === specialEvents?.length
                        ? []
                        : specialEvents?.map((event) => event.specialEventId),
                    );
                  }}
                />
              </LabelLight>
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-300">Name</LabelLight>
              <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-300">Impacted contents</LabelLight>
              <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-300">Timeframe</LabelLight>
              <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-300">Category</LabelLight>
              <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-300">Impact</LabelLight>
            </div>
            <div className="divide-y divide-neutral-100">
              {specialEvents.map((event) => (
                <div
                  key={event.specialEventId}
                  className="grid grid-cols-[40px_1.5fr_1fr_1fr_1fr_0.3fr] px-6 py-1 font-normal text-neutral-500"
                >
                  <div className="my-auto py-3">
                    <Checkbox
                      checked={selectedEventIds.includes(event.specialEventId)}
                      onChange={() => handleSelectEvent(event.specialEventId)}
                    />
                  </div>
                  <div className="my-auto py-3 font-medium text-neutral-700">{event.name}</div>
                  <div className="my-auto py-3 font-normal text-neutral-200">Assign contents</div>
                  <div className="my-auto py-3">
                    {dayjs(event.start).format('MMM D')} - {dayjs(event.end).format('MMM DD, YYYY')}
                  </div>
                  <div className="my-auto py-3">{getCategoryInfo(event.category)?.title}</div>
                  <div className="my-auto py-3 text-center capitalize">{event.impact.toLowerCase()}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      <div>
        {renderSubtitle ? (
          <div>
            <div className="text-mdlg font-medium tracking-normal">Select contents</div>
            <div className="text-md font-normal text-neutral-500">
              Select the content type and contents that are impacted by your event.
            </div>
          </div>
        ) : null}
        <RadioGroup className="ml-[-3px] mt-4 flex flex-col gap-1.5">
          {ASSIGN_CONTENTS_OPTIONS.map((option) => (
            <div key={option.value} className="flex items-center gap-2">
              <FormControlLabel
                className="text-neutral-600"
                checked={option.value === selectedAssignment}
                onChange={() => {
                  setSelectedAssignment(option.value);
                  if (option.value === 'all_contents') {
                    setIncludesAllContents(true);
                  } else {
                    setIncludesAllContents(false);
                  }
                }}
                value={option.value}
                label={option.label}
                control={<Radio />}
                data-cy={option.value}
              />
            </div>
          ))}
        </RadioGroup>
      </div>
      <div>
        {selectedAssignment === 'specified_contents' && (
          <div className="mt-4 flex w-full flex-col gap-2">
            {/*Todo: Uncomment when adding the content rules to special events */}
            {/*<div className="flex w-60 flex-col gap-2">*/}
            {/*  <div className="flex items-center justify-between">*/}
            {/*    <span className="text-neutral-600">Content rules</span>*/}
            {/*    <Switch*/}
            {/*      onToggle={() => setIsSetContentRulesSelected((prevState) => !prevState)}*/}
            {/*      isOn={isContentRulesSelected}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  {isContentRulesSelected ? (*/}
            {/*    <div>*/}
            {/*      <div className="text-sm text-neutral-500">Select content rules</div>*/}
            {/*    </div>*/}
            {/*  ) : null}*/}
            {/*</div>*/}
            <div className="flex justify-between">
              <div className="flex w-60 items-center justify-between">
                <span className="text-neutral-600">Individual contents</span>
                <Switch
                  onToggle={() => {
                    setIsSpecifiedContentsSelected((prevState) => !prevState);
                    if (!isSpecifiedContentsSelected) {
                      setShouldFetch(true);
                    }
                  }}
                  isOn={isSpecifiedContentsSelected}
                />
              </div>
              <div>
                <Button
                  className="rounded-full border-none shadow-none transition-opacity hover:bg-neutral-50 hover:text-neutral-600"
                  onClick={() => setIsIndividualContentsExpanded((prevState) => !prevState)}
                  variant="outline"
                  size="icon"
                >
                  <Styles.ChevronWrap
                    expanded={isIndividualContentsExpanded}
                    className="mx-auto h-full w-fit items-center !justify-center"
                  >
                    <SvgChevronDown />
                  </Styles.ChevronWrap>
                </Button>
              </div>
            </div>
            {isSpecifiedContentsSelected && isIndividualContentsExpanded ? (
              <div className="mt-4">
                <div className="mb-4 flex items-center justify-between gap-2">
                  <PortfolioFilter
                    disabled={false}
                    activeProviderIds={activeProviders?.map((provider) => provider.provider_id)}
                    shouldRenderProvidersFilter
                    shouldRenderLabelsFilter={false}
                    shouldRenderImpactGroupsFilter={false}
                  />
                  <Input
                    value={searchInput}
                    onChange={(e) => handleSearchInput(e.target.value)}
                    onBlur={() => setShouldFetch(true)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        setShouldFetch(true);
                      }
                    }}
                    placeholder="Search contents..."
                    className="h-[32px] px-3 py-2.5 data-[placeholder]:text-red-400"
                  />
                </div>
                <ContentSelectionTableV2
                  portfolioId={portfolioId}
                  providerIds={providerIds}
                  accountIds={[]}
                  filters={selectedOptions}
                  excludePortfolioContents={false}
                  selectedContentIds={selectedContentIds}
                  setSelectedContentIds={setSelectedContentIds}
                  shouldFetch={shouldFetch}
                  setShouldFetch={setShouldFetch}
                  defaultPageSize={10}
                  handleCheckboxAction={(contentId: number) => {
                    const newSelectedIds = selectedContentIds.includes(contentId)
                      ? selectedContentIds.filter((id) => id !== contentId)
                      : [...selectedContentIds, contentId];
                    setSelectedContentIds(newSelectedIds);
                  }}
                  // have it as impact-group-rule to filter the contents that are already in the portfolio
                  configType={'impact-group-rule' as PortfolioRuleType}
                />
              </div>
            ) : null}
            <div>
              {!isIndividualContentsExpanded && selectedContentIds.length ? (
                <div className="mt-4 flex flex-wrap gap-2">
                  <div className="flex flex-wrap gap-2">
                    {selectedContentIds?.map((contentId) => {
                      const content = filteredContents.find((c) => c.contentId === contentId);
                      return (
                        <div
                          key={contentId}
                          className="flex items-center gap-2 rounded-md border border-neutral-100 px-3 py-1"
                        >
                          <AvatarProvider
                            providerId={content?.provider?.provider_id}
                            style={{
                              margin: '0 auto',
                            }}
                            size={32}
                          />
                          <span className="text-md font-normal text-neutral-500">{content?.title}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
