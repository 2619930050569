import { gql, useMutation } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { SPECIAL_EVENTS_QUERY } from './specialEventsQuery';

const CREATE_SPECIAL_EVENT_MUTATION = gql`
  mutation CreateSpecialEvent(
    $category: EventCategory!
    $description: String
    $end: Date!
    $impact: EventImpact!
    $name: String!
    $portfolioId: Float!
    $start: Date!
    $teamId: Float!
  ) {
    createSpecialEvent(
      category: $category
      description: $description
      end: $end
      impact: $impact
      name: $name
      portfolioId: $portfolioId
      start: $start
      teamId: $teamId
    ) {
      specialEvent {
        specialEventId
        name
        description
        impact
        start
        end
        category
        created
      }
    }
  }
`;

type Options = {
  portfolioId: number;
};

function useCreateSpecialEventMutation({ portfolioId }: Options) {
  const { teamId } = useTeam();
  // const { setSpecialEvents } = useSpecialEventsStore();

  return useMutation(CREATE_SPECIAL_EVENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SPECIAL_EVENTS_QUERY,
        variables: { teamId, portfolioId },
      },
    ],
  });
}

export { CREATE_SPECIAL_EVENT_MUTATION, useCreateSpecialEventMutation };
