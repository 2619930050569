import { gql, useQuery } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { NexoyaSpecialEvent } from '../../types';

const SPECIAL_EVENTS_QUERY = gql`
  query SpecialEvents($teamId: Int!, $portfolioId: Int!) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      specialEvents {
        specialEventId
        name
        category
        created
        description
        start
        end
        impact
        includesAllContents
        assignedContents {
          contentId
          title
          provider {
            provider_id
            name
          }
        }
      }
    }
  }
`;

type SpecialEventsResponse = {
  portfolioV2: {
    specialEvents: NexoyaSpecialEvent[];
  };
};

type Options = {
  portfolioId: number;
  onCompleted?: (data: SpecialEventsResponse) => void;
};

function useSpecialEventsQuery({ portfolioId, onCompleted }: Options) {
  const { teamId } = useTeam();

  return useQuery<SpecialEventsResponse>(SPECIAL_EVENTS_QUERY, {
    skip: !teamId || !portfolioId,
    onCompleted,
    variables: {
      teamId,
      portfolioId,
    },
  });
}

export { SPECIAL_EVENTS_QUERY, useSpecialEventsQuery };
