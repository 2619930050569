import React, { Dispatch, SetStateAction } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../components-ui/AlertDialog';
import ButtonAsync from '../../../components/ButtonAsync';
import SvgCheckCircle from '../../../components/icons/CheckCircle';
import Link2 from '../../../components/icons/Link2';
import { LabelLight } from '../../../components/InputLabel/styles';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components-ui/Select';
import {
  NexoyaContentRule,
  NexoyaDeleteContentRuleUserChoiceInput,
  NexoyaDeleteImpactGroupRuleUserChoiceInput,
  NexoyaDiscoveredContent,
  NexoyaImpactGroupRule,
} from '../../../types';
import { Separator } from '../../../components-ui/Separator';
import ContentHoverCard from '../../../components/HoverCard/ContentHoverCard';
import PortfolioRuleHoverCard from '../../../components/HoverCard';

type NexoyaRule = NexoyaContentRule | NexoyaImpactGroupRule;

const isContentRule = (rule: NexoyaRule): rule is NexoyaContentRule => 'contentRuleId' in rule;

interface Props {
  isOpen: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (rule: NexoyaRule) => void;
  rule: NexoyaRule;
  userChoices: NexoyaDeleteContentRuleUserChoiceInput[] | NexoyaDeleteImpactGroupRuleUserChoiceInput[];
  setUserChoices: Dispatch<
    SetStateAction<NexoyaDeleteContentRuleUserChoiceInput[] | NexoyaDeleteImpactGroupRuleUserChoiceInput[]>
  >;
}

export const DeleteRule = ({ isOpen, onCancel, onConfirm, loading, rule, userChoices, setUserChoices }: Props) => {
  const contents = rule.appliedDiscoveredContents;

  const hasAnyOtherRules = (discoveredContent: NexoyaDiscoveredContent) => {
    if (isContentRule(rule)) {
      return discoveredContent.contentRules.some(
        ({ contentRule }) => contentRule?.contentRuleId !== rule.contentRuleId,
      );
    }
    return discoveredContent.impactGroupRules.some(
      ({ impactGroupRule }) => impactGroupRule?.impactGroupRuleId !== rule.impactGroupRuleId,
    );
  };

  const getSelectedUserChoice = (discoveredContentId: number) => {
    // @ts-ignore
    return userChoices.find((choice) => choice.discoveredContentId === discoveredContentId)?.contentRuleId;
  };

  const handleUserChoiceChange = (discoveredContentId: number, newValue: string) => {
    setUserChoices((prevChoices) => {
      const index = prevChoices.findIndex((choice) => choice.discoveredContentId === discoveredContentId);
      let updatedChoice;
      if (isContentRule(rule)) {
        // For content rules:
        // • 'keep_current' means keep the current assignment (no removal, no new assignment)
        // • 'remove_current' means remove the current assignment (set removal flag)
        // • otherwise, treat the value as the new rule id to apply
        updatedChoice =
          newValue === 'keep_current'
            ? { discoveredContentId, removeFunnelStepMappings: false }
            : newValue === 'remove_current'
              ? { discoveredContentId, removeFunnelStepMappings: true }
              : {
                  discoveredContentId,
                  removeFunnelStepMappings: false,
                  applyOtherContentRuleId: Number(newValue),
                };
      } else {
        // For impact group rules:
        updatedChoice =
          newValue === 'keep_current'
            ? { discoveredContentId, removeImpactGroupAssignment: false }
            : newValue === 'remove_current'
              ? { discoveredContentId, removeImpactGroupAssignment: true }
              : {
                  discoveredContentId,
                  removeImpactGroupAssignment: false,
                  applyOtherImpactGroupRuleId: Number(newValue),
                };
      }
      // If there's an existing entry for this discoveredContent, update it.
      if (index > -1) {
        return prevChoices.map((choice, idx) => (idx === index ? updatedChoice : choice));
      }
      // Otherwise, add a new entry.
      return [...prevChoices, updatedChoice];
    });
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent className="min-w-[920px]">
        <AlertDialogHeader className="space-y-3">
          <AlertDialogTitle>Delete {isContentRule(rule) ? 'content rule' : 'impact group rule'}</AlertDialogTitle>
        </AlertDialogHeader>
        <div>
          <div className="mb-3 flex gap-2 rounded-md border border-neutral-100 bg-neutral-50 p-3">
            <Link2 className="h-5 w-5 text-green-400" />
            <div className="flex flex-col">
              <span className="text-md leading-5 text-neutral-800">
                The metric assignment from this rule is applied to the contents below.
              </span>
              <span className="text-xs font-normal leading-5 text-neutral-700">
                To delete this rule, select the metric assignments for the relevant contents.
              </span>
            </div>
          </div>
          <div className="mb-5 flex items-center gap-2 rounded-md border border-neutral-100 bg-neutral-50 p-3">
            <SvgCheckCircle className="h-4 w-4 text-green-400" />
            <span className="text-sm leading-5 text-neutral-800">All contents will remain in the portfolio</span>
          </div>
          {contents?.length ? (
            <div className="rounded-lg border border-neutral-100 bg-neutral-50">
              {/* Table Header */}
              <div className="grid grid-cols-3 items-center px-6 py-3 font-medium text-neutral-600">
                <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Content name</LabelLight>
                <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-500">
                  {isContentRule(rule) ? 'Match with content rules' : 'Match with impact group rules'}
                </LabelLight>
                <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-500">Metric assignment</LabelLight>
              </div>

              <div className="max-h-96 overflow-x-scroll">
                {/* Table Rows */}
                {contents?.map((discoveredContent) => (
                  <div
                    key={discoveredContent.content.contentId}
                    className="grid grid-cols-3 border-t border-neutral-100 px-6 py-4"
                  >
                    {/* Content Name */}
                    <ContentHoverCard
                      content={discoveredContent?.content}
                      tooltip={
                        <div className="max-w-48 truncate text-neutral-900">{discoveredContent.content?.title}</div>
                      }
                    />

                    {/* Matched Rules Column */}
                    <div className="flex gap-2">
                      {isContentRule(rule)
                        ? discoveredContent?.contentRules?.map(({ contentRule }) => (
                            <PortfolioRuleHoverCard
                              key={contentRule?.contentRuleId}
                              rule={contentRule}
                              tooltip={
                                <span
                                  className={
                                    contentRule?.contentRuleId === rule.contentRuleId
                                      ? 'text-red-300'
                                      : 'text-neutral-900'
                                  }
                                >
                                  {contentRule?.name}
                                </span>
                              }
                            />
                          ))
                        : discoveredContent?.impactGroupRules?.map(({ impactGroupRule }) => (
                            <PortfolioRuleHoverCard
                              key={impactGroupRule?.impactGroupRuleId}
                              rule={impactGroupRule}
                              tooltip={
                                <span
                                  className={
                                    impactGroupRule.impactGroupRuleId === rule.impactGroupRuleId
                                      ? 'text-red-400'
                                      : 'text-neutral-900'
                                  }
                                >
                                  {impactGroupRule.name}
                                </span>
                              }
                            />
                          ))}
                    </div>

                    {/* Select for Metric Assignment */}
                    <div>
                      <Select
                        onValueChange={(value) => handleUserChoiceChange(discoveredContent.discoveredContentId, value)}
                        value={getSelectedUserChoice(discoveredContent.discoveredContentId) || undefined}
                      >
                        <SelectTrigger className="border-none bg-transparent p-2">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        {/* Select Options */}
                        <SelectContent className="rounded-lg text-white shadow-md">
                          <SelectItem value="keep_current">
                            <div className="flex flex-col items-start justify-start">
                              <span className="text-md">Keep current configuration</span>
                              <span className="text-[10px] font-medium text-neutral-400">
                                WILL GO INTO UNAPPLIED RULES
                              </span>
                            </div>
                          </SelectItem>
                          <SelectItem value="remove_current">
                            <div className="flex flex-col items-start justify-start">
                              <span className="text-md">Remove current configuration</span>
                              <span className="text-[10px] font-medium text-neutral-400">
                                WILL GO INTO UNAPPLIED RULES
                              </span>
                            </div>
                          </SelectItem>
                          {hasAnyOtherRules(discoveredContent) ? <Separator className="my-2 bg-neutral-600" /> : null}
                          {isContentRule(rule)
                            ? discoveredContent?.contentRules
                                ?.filter(({ contentRule }) => contentRule?.contentRuleId !== rule.contentRuleId)
                                .map(({ contentRule }) => (
                                  <SelectItem
                                    key={contentRule?.contentRuleId}
                                    value={contentRule?.contentRuleId?.toString()}
                                    className="text-left text-mdlg"
                                  >
                                    {contentRule?.name}
                                  </SelectItem>
                                ))
                            : discoveredContent?.impactGroupRules
                                ?.filter(
                                  ({ impactGroupRule }) =>
                                    impactGroupRule?.impactGroupRuleId !== rule.impactGroupRuleId,
                                )
                                .map(({ impactGroupRule }) => (
                                  <SelectItem
                                    key={impactGroupRule?.impactGroupRuleId}
                                    value={impactGroupRule.impactGroupRuleId.toString()}
                                    className="text-left text-mdlg"
                                  >
                                    {impactGroupRule?.name}
                                  </SelectItem>
                                ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <AlertDialogFooter>
          <AlertDialogAction>
            <ButtonAsync
              loading={loading}
              disabled={loading}
              onClick={onCancel}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </ButtonAsync>
          </AlertDialogAction>

          <AlertDialogAction>
            <ButtonAsync
              loading={loading}
              disabled={loading || Object.keys(userChoices).length !== contents?.length}
              onClick={() => onConfirm(rule)}
              variant="contained"
              color="danger"
              size="small"
            >
              {`Apply selection and delete ${isContentRule(rule) ? 'content rule' : 'impact group rule'}`}
            </ButtonAsync>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
