import SidePanel, { SidePanelActions, SidePanelContent } from '../../../../components/SidePanel';
import { StepperWrapper, StepWrapper } from '../../../portfolios/CreatePortfolio';
import VerticalStepper from '../../../../components/VerticalStepper';
import ButtonAsync from '../../../../components/ButtonAsync';
import { MANUAL_SPECIAL_EVENT_CREATION_STEPS } from '../../../../configs/specialEvents';
import { useStepper } from '../../../../components/Stepper';
import React, { useState } from 'react';
import { SpecialEventDetails } from './SpecialEventDetails';
import { SpecialEventAssignContents } from './SpecialEventAssignContents';
import useSpecialEventsStore from '../../../../store/special-events';
import { toast } from 'sonner';
import Button from '../../../../components/Button';
import { useCreateSpecialEventMutation } from '../../../../graphql/specialEvents/mutationCreateSpecialEvent';
import usePortfolioMetaStore from '../../../../store/portfolio-meta';
import dayjs from 'dayjs';
import { useAssignContentsToSpecialEventMutation } from '../../../../graphql/specialEvents/mutationAssignContentsToSpecialEvent';
import { useFilteredContentsStore } from '../../../../store/filter-contents';
import { useTeam } from '../../../../context/TeamProvider';

export const ManuallyCreateSpecialEvent = ({ isOpen, onClose }) => {
  const {
    portfolioMeta: { portfolioId },
  } = usePortfolioMetaStore();

  const { teamId } = useTeam();

  const [specialEventId, setSpecialEventId] = useState<number | null>(null);
  const [includesAllContents, setIncludesAllContents] = useState<boolean>(null);

  const { specialEvents, newSpecialEvent, setSpecialEvents, resetNewSpecialEvent } = useSpecialEventsStore();
  const { selectedContentIds } = useFilteredContentsStore();

  const { step, nextStep, previousStep, resetStep } = useStepper({
    initialValue: 1,
    end: MANUAL_SPECIAL_EVENT_CREATION_STEPS.length,
  });

  const [createSpecialEvent, { loading: loadingCreate }] = useCreateSpecialEventMutation({ portfolioId });
  const [assignContents, { loading: loadingAssignContents }] = useAssignContentsToSpecialEventMutation({ portfolioId });

  const handleSaveEvent = () => {
    createSpecialEvent({
      variables: {
        teamId,
        portfolioId,
        name: newSpecialEvent.name,
        description: newSpecialEvent.description,
        start: dayjs(newSpecialEvent.start).endOf('day').toISOString().slice(0, 10),
        end: dayjs(newSpecialEvent.end).endOf('day').toISOString().slice(0, 10),
        category: newSpecialEvent.category,
        impact: newSpecialEvent.impact,
      },
    })
      .then(({ data }) => {
        if (data?.createSpecialEvent) {
          toast.success('Event successfully added to portfolio');
          setSpecialEvents([...specialEvents, data.createSpecialEvent.specialEvent]);
          setSpecialEventId(data.createSpecialEvent.specialEvent.specialEventId);
          nextStep();
        }
      })
      .catch((err) => {
        toast.error('Failed to create special event');
        console.error('Failed to create special event', err);
      });
  };

  const handleAssignContents = () => {
    assignContents({
      variables: {
        teamId,
        portfolioId,
        specialEventIds: [specialEventId],
        assignedContentIds: includesAllContents ? [] : selectedContentIds,
        includesAllContents,
        contentRuleIds: [],
      },
    })
      .then(({ data }) => {
        if (data?.assignContentsAndRulesToSpecialEvents?.specialEvents) {
          toast.success('Event successfully added to portfolio');
          handleClose();
        }
      })
      .catch((err) => {
        toast.error('Failed to create special event');
        console.error('Failed to create special event', err);
      });
  };

  const handleClose = () => {
    onClose();
    resetStep();
    resetNewSpecialEvent();
  };

  const canSubmit =
    step === 1
      ? newSpecialEvent.name &&
        newSpecialEvent.start &&
        newSpecialEvent.end &&
        newSpecialEvent.category &&
        newSpecialEvent.impact
      : selectedContentIds.length || includesAllContents;

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={handleClose}
      paperProps={{
        style: {
          width: 'calc(100% - 218px)',
          paddingBottom: '78px',
        },
      }}
    >
      <div className="border border-b-[#eaeaea] px-6 py-5">
        <h3 className="text-xl font-medium text-neutral-900">Create events</h3>
      </div>
      <SidePanelContent className="!p-0">
        <StepperWrapper className="!w-[24%] border-r-[1px] border-[#EAEAEA] p-6">
          <VerticalStepper className="max-w-80 !p-0" current={step} steps={MANUAL_SPECIAL_EVENT_CREATION_STEPS} />
        </StepperWrapper>
        <StepWrapper>
          {step === 1 ? (
            <SpecialEventDetails />
          ) : (
            <SpecialEventAssignContents setIncludesAllContents={setIncludesAllContents} />
          )}
        </StepWrapper>
      </SidePanelContent>
      <SidePanelActions>
        {step > 1 ? (
          <Button
            variant="contained"
            style={{
              marginRight: 'auto',
            }}
            disabled={loadingAssignContents}
            onClick={previousStep}
            id="previousStepBtn"
          >
            Previous step
          </Button>
        ) : null}
        <ButtonAsync
          id="next"
          variant="contained"
          color="primary"
          disabled={!canSubmit || loadingCreate || loadingAssignContents}
          loading={loadingCreate || loadingAssignContents}
          onClick={() => {
            if (step === 1) {
              handleSaveEvent();
            } else {
              handleAssignContents();
            }
          }}
          style={{
            marginLeft: 'auto',
          }}
        >
          {step === 1 ? 'Add event and continue' : 'Save'}
        </ButtonAsync>
      </SidePanelActions>
    </SidePanel>
  );
};
