import { VerticalStep } from '../components/VerticalStepper/Step';

export const MANUAL_SPECIAL_EVENT_CREATION_STEPS: VerticalStep[] = [
  {
    id: 'special-event-details',
    name: 'Enter event information',
    description: 'Name the event, set its timeframe and define the event’s impact.',
  },
  {
    id: 'special-event-contents',
    name: 'Assign contents',
    description: 'Define which contents are impacted by the event.',
  },
];

export const UPLOAD_SPECIAL_EVENT_CREATION_STEPS: VerticalStep[] = [
  {
    id: 'upload-file',
    name: 'Upload file',
    description: 'Upload a file with event data and review columns to make sure they align.',
  },
  {
    id: 'special-event-contents',
    name: 'Assign contents',
    description: 'Define which contents are impacted by the event.',
  },
];
