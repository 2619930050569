import { gql, useMutation } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { SPECIAL_EVENTS_QUERY } from './specialEventsQuery';

const BULK_CREATE_SPECIAL_EVENTS_MUTATION = gql`
  mutation BulkCreateSpecialEvents(
    $portfolioId: Float!
    $specialEvents: [BulkCreateSpecialEventInput!]!
    $teamId: Float!
  ) {
    bulkCreateSpecialEvents(portfolioId: $portfolioId, specialEvents: $specialEvents, teamId: $teamId) {
      specialEvents {
        specialEventId
        name
        description
        impact
        start
        end
        category
        created
      }
    }
  }
`;

type Options = {
  portfolioId: number;
};

function useBulkCreateSpecialEventsMutation({ portfolioId }: Options) {
  const { teamId } = useTeam();

  return useMutation(BULK_CREATE_SPECIAL_EVENTS_MUTATION, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,

    refetchQueries: [
      {
        query: SPECIAL_EVENTS_QUERY,
        variables: { teamId, portfolioId },
      },
    ],
  });
}

export { BULK_CREATE_SPECIAL_EVENTS_MUTATION, useBulkCreateSpecialEventsMutation };
