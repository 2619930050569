import React, { Dispatch, SetStateAction } from 'react';
import { ExtendedTable } from '../../Table/ExtendedTable';
import { getData } from '../../../routes/portfolio/components/Content/ContentRule/data-table';
import { getColumns } from '../../../routes/portfolio/components/Content/ContentRule/columns';
import { TableManager } from '../../Table/TableManager';
import { ACTIONS_HEADER_ID } from '../../../routes/portfolio/components/OptimizationProposal/columns';
import { TableStyled } from '../../../routes/portfolio/styles/OptimizationProposal';
import { DataTableFilterOption } from '../../../routes/portfolio/components/Content/ContentRule/types';

import { NexoyaContentFilterFieldName, NexoyaContentFilterOperator } from '../../../types';
import {
  getFilterValueInputBasedOnType,
  PortfolioRuleType,
} from '../../../routes/portfolio/components/Content/ContentRule/utils';
import { useFilteredContentsQuery } from '../../../graphql/portfolioRules/queryFilteredContents';
import { Skeleton } from '../../../components-ui/Skeleton';
import { getDevice } from '../../../utils/media';
import { toast } from 'sonner';
import { useFilteredContentsStore } from '../../../store/filter-contents';

interface Props {
  filters: DataTableFilterOption[];
  providerIds: number[];
  accountIds: number[];
  portfolioId: number;
  shouldFetch?: boolean;
  excludePortfolioContents?: boolean;
  setShouldFetch?: Dispatch<SetStateAction<boolean>>;
  selectedContentIds: number[];
  setSelectedContentIds: Dispatch<SetStateAction<number[]>>;
  configType: PortfolioRuleType;
  handleCheckboxAction: (contentId: number) => void;
  defaultPageSize?: number;
}

function ContentSelectionTableV2({
  filters,
  providerIds,
  accountIds,
  portfolioId,
  shouldFetch,
  setShouldFetch,
  selectedContentIds,
  setSelectedContentIds,
  excludePortfolioContents,
  configType,
  handleCheckboxAction,
  defaultPageSize = 50,
}: Props) {
  const { isLaptop, isTablet, isDesktop, isDesktopL } = getDevice();
  const { filteredContents, setFilteredContents } = useFilteredContentsStore();

  const getDeduplicatedFilters = (filters: DataTableFilterOption[]) =>
    filters.filter(
      (filter, index, self) =>
        filter?.filterValues?.length > 0 &&
        filter?.filterValues?.some((value) => value !== '') &&
        (filter.type !== 'date' ||
          self.findIndex(
            (f) => f.type === filter.type && f.value === filter.value && f.filterOperator === filter.filterOperator,
          ) === index),
    );

  const deduplicatedFilters = getDeduplicatedFilters(filters);

  const { loading } = useFilteredContentsQuery({
    portfolioId,
    filters: [
      ...deduplicatedFilters.map((option) => ({
        fieldName: option.value as NexoyaContentFilterFieldName,
        operator: option.filterOperator as NexoyaContentFilterOperator,
        value: getFilterValueInputBasedOnType(option.type, option.filterValues),
      })),
      {
        fieldName: NexoyaContentFilterFieldName.ProviderId,
        operator: NexoyaContentFilterOperator.Eq,
        value: { numberArr: providerIds?.map((providerId) => providerId) },
      },
      accountIds?.length
        ? {
            fieldName: NexoyaContentFilterFieldName.ParentContentId,
            operator: NexoyaContentFilterOperator.Eq,
            value: { numberArr: accountIds?.map((accountId) => accountId) },
          }
        : null,
    ].filter(Boolean),
    excludePortfolioContents,
    skip: !shouldFetch,
    onCompleted: (data) => {
      const filteredContents =
        configType === 'content-rule'
          ? data?.filterContents
          : data?.filterContents?.filter((fc) => fc.portfolioContentId);
      setFilteredContents(filteredContents || []);
      setShouldFetch(false);
      setSelectedContentIds(filteredContents.map((fc) => fc.contentId) || []);
    },
    onError: (error) => {
      console.error('Error fetching filtered contents:', error);
      toast.error('Error fetching filtered contents');
      setFilteredContents([]);
      setSelectedContentIds([]);
    },
  });

  const tableData = loading
    ? createLoadingData(10)
    : getData({
        content: filteredContents,
        isContentExcluded: (contentId: number) => !selectedContentIds.includes(contentId),
        handleCheckboxAction,
      });

  const contentWidth = isDesktopL ? 940 : isDesktop ? 300 : isLaptop ? 350 : isTablet ? 100 : 300;
  const columns = getProcessedColumns(loading, contentWidth);

  return (
    <TableStyled className="w-full max-w-[1998px]" maxHeight="90vh">
      <ExtendedTable
        tableId="content_table"
        disablePagination={false}
        disableManager={false}
        disableExpanded={false}
        data={tableData}
        columns={columns}
        defaultPageSize={defaultPageSize}
        renderTableManager={({
          columns,
          getToggleHideAllColumnsProps,
          toggleHideAllColumns,
          setStickyColumns,
          stickyColumns,
        }) => (
          <TableManager
            idsNotAllowedToHide={[ACTIONS_HEADER_ID, 'expander', 'content']}
            columns={columns}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            toggleHideAllColumns={toggleHideAllColumns}
            setStickyColumns={setStickyColumns}
            stickyColumns={stickyColumns}
            depth={1}
          />
        )}
      />
    </TableStyled>
  );
}

export default ContentSelectionTableV2;

const createLoadingData = (count: number) => Array(count).fill({});

const getProcessedColumns = (loading: boolean, contentWidth: number) => {
  const baseColumns = getColumns({ contentWidth });
  if (loading) {
    return baseColumns.map((column) => ({
      ...column,
      columns: column.columns?.map((subColumn) => ({
        ...subColumn,
        Cell: () => <Skeleton className="h-5 w-full" />,
      })),
    }));
  }
  return baseColumns;
};
