import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../../../components/Button';
import { CancelIcon } from '../../../../components/icons';
import SvgInfoCircle from '../../../../components/icons/InfoCircle';
import { nexyColors } from '../../../../theme';
import { LabelLight } from '../../../../components/InputLabel/styles';
import Papa from 'papaparse';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components-ui/Select';
import { NexoyaEventCategory, NexoyaEventImpact, NexoyaSpecialEvent } from '../../../../types';
import Spinner from '../../../../components/Spinner';
import { toast } from 'sonner';
import useSpecialEventsStore from '../../../../store/special-events';
import { findDuplicateEvents } from '../../utils/special-events';
import { DuplicateEventsDialog } from './DuplicateEventsDialog';

interface SpecialEventColumnMapping {
  name: string;
  description?: string;
  start: string;
  end: string;
  category: string;
  impact: string;
}

const NEEDED_COLUMNS = [
  { field: 'name' as const, label: 'Event name' },
  { field: 'description' as const, label: 'Event description', optional: true },
  { field: 'start' as const, label: 'Start date' },
  { field: 'end' as const, label: 'End date' },
  { field: 'category' as const, label: 'Event category' },
  { field: 'impact' as const, label: 'Impact level' },
];

const DEFAULT_MAPPING: SpecialEventColumnMapping = {
  name: '',
  description: '',
  start: '',
  end: '',
  category: '',
  impact: '',
};

export interface DuplicateEvent {
  existing: NexoyaSpecialEvent;
  new: Partial<NexoyaSpecialEvent>;
}

export const SpecialEventsFileUpload = ({ setParsedEvents }) => {
  const [error, setError] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [parsedData, setParsedData] = useState([]);
  const [parsedHeaders, setParsedHeaders] = useState<string[]>([]);
  const [columnMapping, setColumnMapping] = useState<SpecialEventColumnMapping>(DEFAULT_MAPPING);
  const [mappingValid, setMappingValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const { specialEvents } = useSpecialEventsStore();

  const [duplicateEvents, setDuplicateEvents] = useState<DuplicateEvent[]>([]);
  const [showDuplicatesDialog, setShowDuplicatesDialog] = useState(false);

  useEffect(() => {
    if (uploadedFile && parsedHeaders.length === Object.keys(columnMapping).length) {
      setLoading(true);
      const newEvents = convertToSpecialEvents();
      const duplicates = findDuplicateEvents(newEvents, specialEvents);

      if (duplicates.length > 0) {
        setDuplicateEvents(duplicates);
        setShowDuplicatesDialog(true);
        setLoading(false);
      } else {
        setParsedEvents(newEvents);
        setLoading(false);
      }
    }
  }, [columnMapping, mappingValid, uploadedFile]);

  useEffect(() => {
    if (uploadedFile && parsedHeaders.length === Object.keys(columnMapping).length) {
      setLoading(true);
      setTimeout(() => {
        const events = convertToSpecialEvents();
        setParsedEvents(events);
        setLoading(false);
      }, 1000);
    }
  }, [columnMapping, mappingValid, uploadedFile]);

  useEffect(() => {
    if (uploadedFile && parsedHeaders?.length) {
      const detectColumn = (patterns: string[]): string => {
        return (
          parsedHeaders.find((header) =>
            patterns.some((pattern) => header.toLowerCase().includes(pattern.toLowerCase())),
          ) ?? ''
        );
      };

      setColumnMapping({
        name: detectColumn(['name', 'event name', 'title']),
        description: detectColumn(['description', 'desc']),
        start: detectColumn(['start', 'startDate', 'from']),
        end: detectColumn(['end', 'endDate', 'to']),
        category: detectColumn(['category', 'type']),
        impact: detectColumn(['impact', 'level', 'severity']),
      });
    }
  }, [uploadedFile, parsedHeaders]);

  const validateMapping = (mapping: SpecialEventColumnMapping) => {
    const requiredFields: (keyof SpecialEventColumnMapping)[] = ['name', 'start', 'end', 'category', 'impact'];
    return requiredFields.every((field) => mapping[field] !== '');
  };

  const handleColumnSelect = (field: keyof SpecialEventColumnMapping, value: string) => {
    const newMapping = { ...columnMapping, [field]: value };
    setColumnMapping(newMapping);
    setMappingValid(validateMapping(newMapping));
  };

  const handleDuplicateResolution = (selections: Record<number, 'new' | 'existing'>) => {
    const newEvents = convertToSpecialEvents().filter((newEvent) => {
      const duplicate = duplicateEvents.find((d) => d.new.name === newEvent.name);
      if (!duplicate) return true;
      return selections[duplicate.existing.specialEventId] === 'new';
    });

    setParsedEvents(newEvents);
    setShowDuplicatesDialog(false);
    setDuplicateEvents([]);
  };

  const convertToSpecialEvents = (): Partial<NexoyaSpecialEvent>[] => {
    return parsedData.map((row: any) => ({
      __typename: 'SpecialEvent',
      specialEventId: Math.random(),
      name: row[columnMapping.name],
      description: columnMapping.description ? row[columnMapping.description] : null,
      start: new Date(row[columnMapping.start]),
      end: new Date(row[columnMapping.end]),
      category: row[columnMapping.category] as NexoyaEventCategory,
      impact: row[columnMapping.impact] as NexoyaEventImpact,
      created: new Date().toISOString(),
    }));
  };

  const handleFileUpload = (file: File) => {
    if (file.type !== 'text/csv') {
      toast.error('Please upload a CSV file');
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          toast.error('Error parsing CSV file');
          console.error('Parse errors:', results.errors);
          return;
        }

        const headers = Object.keys(results.data[0] || {});
        setError(null);
        setUploadedFile(file);
        setParsedData(results.data);
        setParsedHeaders(headers);
      },
      error: (error) => {
        toast.error('Failed to parse CSV file');
        console.error('Parse error:', error);
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      }
    },
  });

  console.debug({ parsedData });

  return (
    <div className="flex max-w-xl flex-col gap-8 py-8">
      <div>
        <div className="text-[20px] font-medium tracking-normal">Upload event file</div>
        <div className="text-md font-normal text-neutral-500">
          Upload a CSV with at least one event to bulk upload to Nexoya.
        </div>
      </div>
      <div className="line-height-2 text-sm font-medium tracking-normal text-neutral-300">
        Each event row should have the following columns:
        <ul className="list-disc">
          <li className="ml-4">Event name</li>
          <li className="ml-4">Event description (Optional)</li>
          <li className="ml-4">Start date and end date</li>
          <li className="ml-4">Event category</li>
          <li className="ml-4">Impact level</li>
        </ul>
        <div className="mt-3">It cannot contain additional columns, special characters or missing fields.</div>
      </div>
      <div className="flex flex-col gap-2">
        {loading ? (
          <div
            className="flex min-h-[216px] cursor-pointer flex-col items-center justify-center gap-2 rounded-lg bg-neutral-50 p-4 transition-colors hover:bg-[#FBFCFC]"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C7C8D1FF' stroke-width='4' stroke-dasharray='12%2c12' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
            }}
          >
            <input {...getInputProps()} disabled />
            <div className="text-center text-neutral-300">
              <Spinner size="24px" variant="light" />
              <div className="mt-3 text-xs">Uploading file and detecting columns...</div>
            </div>
          </div>
        ) : uploadedFile ? (
          <div className="flex flex-col gap-6">
            <div>
              <div className="mb-2 text-mdlg font-medium tracking-normal">Uploaded file</div>
              <div className="flex w-fit items-center gap-2 rounded-md border border-neutral-100 px-3 py-2 text-neutral-400">
                {uploadedFile.name}
                <span onClick={() => setUploadedFile(null)} className="cursor-pointer hover:text-neutral-800">
                  <CancelIcon style={{ height: 10 }} />
                </span>
              </div>
            </div>
            <div className="text-md font-normal text-neutral-500">
              Review the columns from your CSV to make sure they align with the predefined column names below.
            </div>
            <div>
              <div className="rounded-md border border-neutral-100 bg-neutral-50 px-3 py-2 font-normal text-neutral-600">
                <SvgInfoCircle style={{ color: nexyColors.neutral400, height: 16, width: 16, margin: '4px 8px 0 0' }} />
                Separate start date and end date columns will be merged into one column called “Timeframe”.
              </div>
            </div>
            <div className="rounded-lg border border-neutral-100">
              <div className="grid grid-cols-[1fr_1fr] px-6 py-3 font-medium text-neutral-600">
                <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-300">Column name</LabelLight>
                <LabelLight className="!mb-0 px-2 text-end font-semibold !text-neutral-300">CSV column</LabelLight>
              </div>
              <div className="grid grid-cols-[1fr_1fr] border-t border-neutral-100 px-6 py-1">
                {NEEDED_COLUMNS.map(({ field, label, optional }) => (
                  <React.Fragment key={field}>
                    <div className="my-auto py-4">
                      {label}
                      {optional && <span className="text-neutral-300"> (Optional)</span>}
                    </div>
                    <div className="flex justify-end py-4 text-end font-normal">
                      <Select value={columnMapping[field]} onValueChange={(value) => handleColumnSelect(field, value)}>
                        <SelectTrigger className="w-fit border-none bg-white p-2">
                          <SelectValue placeholder={`Select ${label.toLowerCase()}`} />
                        </SelectTrigger>
                        <SelectContent>
                          {parsedHeaders.map((header) => (
                            <SelectItem key={header} value={header}>
                              {header}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className="flex min-h-[216px] cursor-pointer flex-col items-center justify-center gap-2 rounded-lg bg-neutral-50 p-4 transition-colors hover:bg-[#FBFCFC]"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C7C8D1FF' stroke-width='4' stroke-dasharray='12%2c12' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
            }}
          >
            <input {...getInputProps()} />
            <div className="text-center text-neutral-600">
              <div className="mb-1 text-mdlg text-neutral-500">Upload CSV file</div>
              <div className="mb-4 text-sm font-normal text-neutral-400">
                {isDragActive
                  ? 'Drop the CSV file here'
                  : 'Drag and drop a CSV file here, or click the button to select a file.'}
              </div>
              <Button color="primary" size="small" variant="contained">
                Select CSV file
              </Button>
            </div>
          </div>
        )}
        {error && <div className="text-sm text-red-500">{error}</div>}
        <DuplicateEventsDialog
          duplicates={duplicateEvents}
          isOpen={showDuplicatesDialog}
          onClose={() => setShowDuplicatesDialog(false)}
          onConfirm={handleDuplicateResolution}
          numberOfImportedEvents={parsedData.length}
        />
      </div>
    </div>
  );
};
