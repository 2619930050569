import { gql, useMutation } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { SPECIAL_EVENTS_QUERY } from './specialEventsQuery';

const DELETE_SPECIAL_EVENT_MUTATION = gql`
  mutation DeleteSpecialEvent($portfolioId: Float!, $specialEventId: Float!, $teamId: Float!) {
    deleteSpecialEvent(portfolioId: $portfolioId, specialEventId: $specialEventId, teamId: $teamId)
  }
`;

type Options = {
  portfolioId: number;
};

function useDeleteSpecialEventMutation({ portfolioId }: Options) {
  const { teamId } = useTeam();

  return useMutation(DELETE_SPECIAL_EVENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    onQueryUpdated: (result, diff) => {
      console.debug('OnQueryUpdated for delete, ', { result, diff: diff.result?.portfolioV2?.specialEvents });
      // setSpecialEvents(result.data.createSpecialEvent.specialEvent);
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: SPECIAL_EVENTS_QUERY,

        variables: { teamId, portfolioId },
      },
    ],
  });
}

export { DELETE_SPECIAL_EVENT_MUTATION, useDeleteSpecialEventMutation };
