import { gql, useMutation } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { SPECIAL_EVENTS_QUERY } from './specialEventsQuery';
import { NexoyaAssignContentsAndRulesToSpecialEventsResponse } from '../../types';
import useSpecialEventsStore from '../../store/special-events';

const ASSIGN_CONTENTS_TO_SPECIAL_EVENT_MUTATION = gql`
  mutation AssignContentsToSpecialEvent(
    $assignedContentIds: [Float]!
    $contentRuleIds: [Float]!
    $includesAllContents: Boolean!
    $portfolioId: Float!
    $specialEventIds: [Float!]!
    $teamId: Float!
  ) {
    assignContentsAndRulesToSpecialEvents(
      assignedContentIds: $assignedContentIds
      contentRuleIds: $contentRuleIds
      includesAllContents: $includesAllContents
      portfolioId: $portfolioId
      specialEventIds: $specialEventIds
      teamId: $teamId
    ) {
      specialEvents {
        specialEventId
        includesAllContents
        name
        created
        category
        start
        end
        assignedContents {
          contentId
          title
        }
      }
    }
  }
`;

type Options = {
  portfolioId: number;
};

function useAssignContentsToSpecialEventMutation({ portfolioId }: Options) {
  const { teamId } = useTeam();
  const { setSpecialEvents, specialEvents } = useSpecialEventsStore();
  return useMutation(ASSIGN_CONTENTS_TO_SPECIAL_EVENT_MUTATION, {
    onCompleted: (data: {
      assignContentsAndRulesToSpecialEvents: NexoyaAssignContentsAndRulesToSpecialEventsResponse;
    }) => {
      // merge the new special events with the existing ones
      setSpecialEvents(
        specialEvents.map((specialEvent) => {
          const newSpecialEvent = data.assignContentsAndRulesToSpecialEvents.specialEvents.find(
            (newSpecialEvent) => newSpecialEvent.specialEventId === specialEvent.specialEventId,
          );
          return newSpecialEvent || specialEvent;
        }),
      );
    },
    refetchQueries: [
      {
        query: SPECIAL_EVENTS_QUERY,
        variables: { teamId, portfolioId },
      },
    ],
  });
}

export { ASSIGN_CONTENTS_TO_SPECIAL_EVENT_MUTATION, useAssignContentsToSpecialEventMutation };
